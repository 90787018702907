<template>
  <v-dialog max-width="550" persistent v-model="dialog">
    <v-card v-if="showConfirmationDialog">
      <v-card-title class="headline">Confirmation</v-card-title>
      <v-card-text>
        <v-card>
          <v-card-text>
            <p>{{ $t('msg.score_generated_text_1') }}</p>
            <p>{{ $t('msg.score_generated_text_2') }}</p>
            <v-card-actions>
              <v-btn class="primary" @click="destroy" >{{ $t('cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="hideConfirmationDialog">{{ $t('confirm') }}</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline">{{ $t('msg.edit_customer_info') }}</v-card-title>
      <div class="notes"><b style="color: red">*</b> {{ $t('msg.asterisk_remark') }}</div>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{failedRules, failed ,passes}">
          <form>
            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider :rules="{required: false, min : 1, max : 20, regex:/^[a-zA-Z0-9]+$/ }"
                                  name="applicationNumber" ref="observerEditApplicationNumber"
                                  v-slot="{errors, failedRules}">
                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'applicationNumber')"
                              :label="$t('lbl.application_id')" @input="customer.applicationNumber = customer.applicationNumber.toUpperCase()"
                              v-model="customer.applicationNumber"></v-text-field>
              </ValidationProvider>
            </v-skeleton-loader>

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider :rules="{required : true, min:3, max:80, regex: /^[a-zA-Z\s-/@']+$/}"
                                  v-slot="{errors, failedRules}">
                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'firstname')"
                              :label="$t('lbl.firstname')" v-model="customer.firstname"></v-text-field>
              </ValidationProvider>
            </v-skeleton-loader>

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider :rules="{required : true, min:3, max:80, regex: /^[a-zA-Z\s-/@']+$/}"
                                  v-slot="{errors, failedRules}">
                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'lastname')"
                              :label="$t('lbl.lastname')" v-model="customer.lastname"></v-text-field>
              </ValidationProvider>
            </v-skeleton-loader>

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider :rules="{required : true ,min:1, max:20, regex: /^[a-zA-Z0-9/-]+$/}"-->
<!--                                  name="idNumber"-->
<!--                                  class="requiredSticker"-->
<!--                                  v-slot="{ errors, failedRules }">-->
<!--                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'idNumber')"-->
<!--                              @keyup="uppercase"-->
<!--                              readonly disabled-->
<!--                              :label="$t('lbl.id_number')"-->
<!--                              v-model="customer.idNumber">-->
<!--                </v-text-field>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider :rules="{required : true}" name="gender"-->
<!--                                  v-slot="{ errors, failedRules }">-->
<!--                <v-autocomplete :error-messages="$translatedErrorMessages(failedRules,'gender')"-->
<!--                                :items="dropdown.genders"-->
<!--                                item-text="text"-->
<!--                                item-value="value"-->
<!--                                class="requiredSticker"-->
<!--                                :label="$t('lbl.gender')"-->
<!--                                v-model="customer.gender"-->
<!--                ></v-autocomplete>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider :rules="{required : true, min:2, max:100, regex:/^[a-zA-Z0-9%.&(),/'\s-]+$/ }"-->
<!--                                  v-slot="{errors, failedRules}">-->
<!--                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'position')"-->
<!--                              :label="$t('lbl.position')"-->
<!--                              class="requiredSticker"-->
<!--                              v-model="customer.designation"></v-text-field>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider :rules="{required : true, max:20, min: 3, regex: /^[a-zA-Z0-9]+$/}" name="brNo"-->
<!--                                  v-slot="{errors, failedRules}">-->
<!--                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'brNumber')"-->
<!--                              @keyup="uppercase"-->
<!--                              class="requiredSticker uppercase"-->
<!--                              :label="$t('lbl.business_reg_num')"-->
<!--                              v-model="customer.businessRegistrationNumber">-->
<!--                </v-text-field>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider-->
<!--                  :rules="{required: true, min: 5, max: 80, regex: /^[a-zA-Z0-9.&()'\s-]+$/}"-->
<!--                  v-slot="{errors, failedRules}">-->
<!--                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'companyName')"-->
<!--                              :label="$t('lbl.company_name')"-->
<!--                              class="requiredSticker"-->
<!--                              v-model="customer.companyName">-->
<!--                </v-text-field>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">-->
<!--              <ValidationProvider-->
<!--                  :rules="{required: true}"-->
<!--                  v-slot="{errors, failedRules}">-->
<!--                <v-text-field :error-messages="$translatedErrorMessages(failedRules,'branch')" disabled-->
<!--                              :label="$t('lbl.branch')"-->
<!--                              class="requiredSticker"-->
<!--                              v-model="customer.branch">-->
<!--                </v-text-field>-->
<!--              </ValidationProvider>-->
<!--            </v-skeleton-loader>-->

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider :rules="{required : true}" name="product"
                                  v-slot="{ errors, failedRules }">
                <v-autocomplete :error-messages="$translatedErrorMessages(failedRules,'product')"
                                :items="$parent.filters.scheme"
                                @change="handleProductChange"
                                item-text="label"
                                item-value="code"
                                class="requiredSticker"
                                :label="$t('lbl.product_applied')"
                                v-model="customer.productApplied"
                ></v-autocomplete>
              </ValidationProvider>
            </v-skeleton-loader>

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line" v-if ="financingType==='SME'||financingType==='MIKRO'">
              <ValidationProvider :rules="{required : false}" name="businessCategory"
                                  v-slot="{ errors, failedRules }">
                <v-autocomplete :error-messages="$translatedErrorMessages(failedRules,'business_category')"
                                :items="dropdown.businessCategories"
                                item-text="text"
                                item-value="value"
                                class="requiredSticker"
                                :label="$t('lbl.business_category')"
                                v-model="customer.businessCategory"
                ></v-autocomplete>
              </ValidationProvider>
            </v-skeleton-loader>

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider
                  :rules="{ required: false, numeric: true, min_value: selectedProduct.validation.minAmount, max_value : selectedProduct.validation.maxAmount}"
                  v-slot="{errors, failedRules}">
                <v-text-field
                    :error-messages="$translatedErrorMessages(failedRules,'financingAmount', [currency, selectedProduct.validation.minAmount, selectedProduct.validation.maxAmount])"
                    class="requiredSticker"
                    :label="$t('lbl.financing_amount_applied')"
                    :prefix="currency"
                    v-model="customer.loanAmount">
                </v-text-field>
              </ValidationProvider>
            </v-skeleton-loader>

            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line">
              <ValidationProvider
                  :rules="{ required: false, numeric: true, min_value: selectedProduct.validation.minTenure, max_value : selectedProduct.validation.maxTenure}"
                  v-slot="{errors, failedRules}">
                <v-text-field
                    :error-messages="$translatedErrorMessages(failedRules,'tenureApplied', [selectedProduct.validation.minTenure, selectedProduct.validation.maxTenure])"
                    class="requiredSticker"
                    :label="$t('lbl.financing_tenure_applied')"
                    required
                    suffix="Months"
                    v-model="customer.tenureApplied">
                </v-text-field>
              </ValidationProvider>
            </v-skeleton-loader>

<!--            <v-skeleton-loader :loading="loading" transition="scale-transition" type="list-item-two-line" v-if="!isLoanOfficer">
              <ValidationProvider :rules="{required : false}" name="loanOfficer"
                                  v-slot="{ errors, failedRules }">
                <v-autocomplete :error-messages="$translatedErrorMessages(failedRules,'loan_officer')"
                                :items="dropdown.loanOfficer"
                                item-text="value"
                                item-value="value"
                                :label="$t('lbl.loan_officer_assign')"
                                v-model="customer.loanOfficer"
                ></v-autocomplete>
              </ValidationProvider>
            </v-skeleton-loader>-->

            <div>
              <div v-show="failed">
                <p style="color:red" >{{ $t('msg.failed_action') }}</p>
              </div>
              <div v-show="saveError">
                <p style="color:red" >{{ $t('msg.failed_action') }}</p>
              </div>
              <div v-show="serverError">
                <p style="color:red" >{{ $t('error.server') }}</p>
              </div>
              <div v-show="informationTampering">
                <p style="color:red" >{{ $t('error.information_tampering') }}</p>
              </div>
            </div>

            <v-card-actions align="right">
              <v-spacer></v-spacer>
              <v-skeleton-loader class="mr-3" :loading="loading||sending" transition="scale-transition" type="button">
                <v-btn @click="destroy" color="primary">{{ $t('cancel') }}</v-btn>
              </v-skeleton-loader>
              <v-skeleton-loader :loading="loading||sending" transition="scale-transition" type="button">
                <v-btn @click="passes(saveCustomer)" color="primary" dark>{{ $t('save') }}
                </v-btn>
              </v-skeleton-loader>
            </v-card-actions>
          </form>
        </ValidationObserver>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {dataServices} from "@/services";

export default {
  name: 'CustomerMaintenance',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      loading: false,
      dialog: false,
      menu: false,
      dropdown: {},
      branches:[],
      date: new Date().toISOString().substr(0, 10),
      editedCommencementDate:'',
      editBusinessCategoryDropdownData: [],
      validation: false,
      customer : {},
      selectedProduct: {
        validation: {
          minAmount: 1000,
          maxAmount: 50000,
          minTenure: 1,
          maxTenure: 120
        }
      },
      malaysianOwnedItems: [
        {text: 'Yes', value: 'Yes'},
        {text: 'No', value: 'No'}
      ],
      errors: [],
      showConfirmationDialog: false,
      failed: false,
      serverError: false,
      saveError: false,
      informationTampering: false,
      sending: false,
      currency: '$',
      financingType: '',
    }
  },
  methods: {
    init(customer, validation, financingType) {
      this.loading = true
      this.financingType = financingType
      this.customer = Object.assign({}, customer)
      this.validation = validation
      if (this.customer.gfiScoreGenerationCount === null || (this.customer.gfiScoreGenerationCount > 0 && !this.customer.hasGfiScore) ){
        this.showConfirmationDialog = false
      }else if(this.customer.gfiScoreGenerationCount === 1) {
        this.showConfirmationDialog = true
      }
      this.initDropdown()
      this.dialog = true
      let productValidation = this.$parent.filters.scheme.filter(f => f.code === this.customer.productApplied)[0].validation
      if(productValidation)
        this.selectedProduct.validation=productValidation
    },
    initDropdown: function () {
      dataServices.getUserDropDowns(this.$parent.userType.toUpperCase()).then(response => {
        this.dropdown = response.dataResponse
        this.branches = response.dataResponse.branch
        this.identificationNumberTypes = response.dataResponse.identificationNumberTypes
        this.loading = false
      })
    },
    saveCustomer() {
      this.sending=true
      this.serverError = false
      this.informationTampering = false
      dataServices.saveCustomer(this.customer).then(r => {
        console.log(this.customer)
        this.sending=false
        if (r.successful) {
          this.$parent.renderMessage('Successfully updated customer information.')
          this.dialog = false
        } else {
          console.log('error')
          r.errorList.forEach(e => {
            if(e.field==='applicationNumber' && e.rule==='duplicate'){
              this.$refs.observerEditApplicationNumber.applyResult({
                errors: ['error'],
                failedRules: ['duplicate']
              });
            }
            if(e.field==='assessment' && e.rule==='duplicate'){
              this.informationTampering=true
            }
            if(e.field==='businessRegistrationNumber' && e.rule==='duplicate'){
              this.informationTampering=true
            }
            if(e.field==='idNumber' && e.rule==='duplicate'){
              this.informationTampering=true
            }
          })
        }
      }).catch(error => {
        console.log(error)
        this.sending=false
        this.serverError = true
        console.log('Error' + error)
      })
    },
    hideConfirmationDialog() {
      this.showConfirmationDialog = false
    },
    destroy() {
      this.dialog = false
      this.serverError = false
      this.saveError = false
      this.informationTampering = false
    },
    handleProductChange () {
      this.selectedProduct.validation = this.$parent.filters.scheme.filter(f => f.code === this.customer.productApplied)[0].validation
    },
    uppercase() {
      this.customer.idNumber = this.customer.idNumber.toUpperCase();
      this.customer.businessRegistrationNumber = this.customer.businessRegistrationNumber.toUpperCase();
    }
  },
  computed: {
    isLoanOfficer: function () {
      return this.$store.state.home.user.roles.includes('ROLE_LOAN_OFFICER')
    }
  },
  watch: {
    'customer.commencementDate': function () {
      if (this.customer.commencementDate) {
        const [year, month, day] = this.customer.commencementDate.substr(0, 10).split('-')
        this.editedCommencementDate = `${day}-${month}-${year}`
      } else
        this.editedCommencementDate = ''
    },
    editedCommencementDate: function (newCommencementDate) {
      if (!newCommencementDate) {
        this.customer.commencementDate = null
      }
    }
  },
}
</script>

<style>
.requiredSticker label::after {
  content: "*";
  color: red;
}
.notes {
  padding-left: 27px;
  margin-top: -15px;
  font-size: 12px;
  color: grey;
}
.uppercase input {
  text-transform: uppercase;
}
.msg-error {
  font-size: 12px;
  font-family: 'Ubuntu', sans-serif;
  color: #a70f1c;
  line-height: 1;
  margin-top: -27px !important;
  padding-left: 13px;
}
.tenure {
  margin-bottom: -30px !important;
  margin-top: -10px !important;
  font-size: 12px;
}
</style>
